<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-simple-table dense class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Ambil Tabungan Anggota</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-col cols="2" md="2">
                <v-text-field
                  label="Tahun"
                  type="number"
                  hide-details
                  outlined
                  dense
                  v-model="filter.tahun"
                  @change="optionChange()"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  class="mt-8"
                  style="width: 200px"
                  label="Jenis Upah"
                  v-model="filter.jenis_upah"
                  :items="jenis_upah"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  @change="optionChange"
                ></v-select>
              </v-col>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" small @click="optionChange()"> Lihat </v-btn>
              &nbsp;
              <v-btn color="secondary" small @click="cetak()">Cetak</v-btn>
              &nbsp;
              <v-btn color="secondary" small @click="export_excel()">Export Excel</v-btn>
            </v-toolbar>
          </template>

          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: center">NO</th>
                <th style="text-align: center">ANGGOTA</th>
                <th style="text-align: center">BAGIAN</th>
                <th style="text-align: center">JNS UPAH</th>
                <th style="text-align: center">PROGRAM TABUNGAN</th>
                <th style="text-align: center">TABUNGAN</th>
                <th style="text-align: center">TOTAL AMBIL</th>
                <th style="text-align: center">PENGAMBILAN</th>
                <th style="text-align: center">AKSI</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in desserts" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{ item.nama + ' (' + item.kode_anggota + ')' }}</td>
                <td>{{ item.divisi }}</td>
                <td>{{ item.jenis_upah }}</td>
                <td>{{ item.nama_jenis }}</td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.besar_tabungan) }}
                </td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.custome_ambil) }}
                </td>
                <td>
                  <span v-if="item.besar_ambil == ''"></span>
                  <span v-else>
                    {{
                      new Intl.NumberFormat(['id']).format(item.besar_ambil) + ' (' + formatDate(item.tgl_ambil) + ')'
                    }}
                  </span>
                </td>

                <td>
                  <v-checkbox
                    v-model="selected"
                    v-if="item.besar_ambil == ''"
                    :value="item.id + '::' + item.custome_ambil + '::' + item.status"
                    label="Pilih"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6" style="text-align: center">TOTAL</td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer.total) }}</td>
                <td></td>
                <td>
                  <v-checkbox class="mt-4" v-model="selectedAll" @click="selectAll()" label="Pilih Semua"></v-checkbox>
                  <v-btn class="mb-2" color="primary" :loading="loadingSimpan" @click="simpan()">Simpan</v-btn>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
table tbody tr td,
table thead tr th,
table tfoot tr td {
  border: 1px solid grey;
}
</style>
<script>
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      desserts: [],
      filter: {
        jenis_upah: 'HARIAN',
        tahun: new Date().toJSON().slice(0, 4),
      },
      jenis_upah: ['HARIAN', 'MINGGUAN', 'BULANAN'],

      footer: {
        total: 0,
      },
      selected: [],
      selectedAll: false,
      loadingSimpan: false,
    }
  },
  created() {
    this.getDataFromApi()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    // date_harian: {
    //   handler() {
    //     this.getDataFromApi()
    //   },
    //   deep: true,
    // },
  },
  methods: {
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('jenis_upah', this.filter.jenis_upah)
      form.append('tahun', this.filter.tahun)
      axios
        .post(`${apiRoot}/api/Tabungan/getTabunganAnggotas`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.selected = []
            this.desserts = response.data.data
            this.footer.total = 0
            response.data.data.forEach(item => {
              this.footer.total += parseFloat(item.custome_ambil)
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    selectAll() {
      this.selected = []
      if (this.selectedAll === true) {
        this.desserts.forEach(item => {
          if (item.besar_ambil == null || item.besar_ambil == '') {
            this.selected.push(item.id + '::' + item.custome_ambil + '::' + item.status)
          }
        })
      } else {
        this.selected = []
      }
    },
    simpan() {
      this.loadingSimpan = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('datas', this.selected)
      axios
        .post(`${apiRoot}/api/Tabungan/ambilTabunganAnggotas`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            alert(response.data.message)
            this.selected = []
            this.getDataFromApi()
          } else {
            alert(response.data.message)
          }
          this.loadingSimpan = false
        })
        .catch(error => {
          alert(error)
          this.loadingSimpan = false
        })
    },
    optionChange() {
      // if (this.filter.tanggal_mulai != '' && this.filter.tanggal_akhir != '') {
      this.getDataFromApi()
      // }
    },
    cetak() {
      window.open(
        `${apiRoot}/api/Tabungan/getTabunganAnggotas/${this.token}/cetak/${this.filter.jenis_upah}/${this.filter.tahun}`,
        '_blank',
      )
    },
    export_excel() {
      window.open(
        `${apiRoot}/api/Tabungan/getTabunganAnggotas/${this.token}/excel/${this.filter.jenis_upah}/${this.filter.tahun}`,
        '_blank',
      )
    },
  },
}
</script>
